import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps, USER_ADMIN_GROUP, USER_SUPER_ADMIN } from '../common/data/userDummyData';
import { useClerk, useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../components/bootstrap/Toasts';

export interface IAuthContextProps {
  user: string;
  setUser?(...args: unknown[]): unknown;
  userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const { addToast } = useToasts();

  const { signOut } = useClerk();
  const { user: userDataClerk } = useUser();

  const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
  const [userData, setUserData] = useState<Partial<IUserProps>>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showToast = (type: any, title: any, text: any) =>
    addToast(
      <Toasts
        icon={type}
        iconColor={type} // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
        title={title}
        //time={'String'}
        isDismiss={true}>
        {text}
      </Toasts>,
      {
        autoDismiss: true,
        autoDismissTimeout: 3000, // Examples: 1000, 3000, ...
      },
    );

  useEffect(() => {
    localStorage.setItem('facit_authUsername', user);
  }, [user]);

  useEffect(() => {
    if (!userDataClerk?.id) return;

    const fetchUser = async () => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/${userDataClerk.id}`)
        .then((response) => {
          const roleId = response.data.user.role.uuid;

          if (![USER_ADMIN_GROUP, USER_SUPER_ADMIN].includes(roleId)) {
            return signOut(() => {
              showToast('danger', 'Error', 'Unauthorized user');
            });
          }

          setUser(response.data.user);
          setUserData(response.data.user);
          localStorage.setItem('access_token', response.data.access_token);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataClerk?.id]);

  const value = useMemo(
    () => ({
      user,
      setUser,
      userData,
    }),
    [user, userData],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
