import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo1 from '../../../assets/img/logo.jpeg';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import axios from 'axios';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  username: Yup.string().email().required('Usuario es requerido'),
});

interface IForgotPasswordHeaderProps {
  isNewUser?: boolean;
}
const ForgotPasswordHeader: FC<IForgotPasswordHeaderProps> = () => {
  return (
    <>
      <div className='text-center h1 fw-bold mt-3 mb-5'>Forgot your password?</div>
      <div className='text-center h4 text-muted mb-5'>
        Please enter the email you use to sign in
      </div>
    </>
  );
};

interface IForgotPasswordProps {
  isSignUp?: boolean;
}

const ForgotPassword: FC<IForgotPasswordProps> = () => {
  const { setUser } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();

  const [hasErrors, setErrors] = useState<boolean>(false);
  const [noUser, setNoUser] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/'), [navigate]);
  const [post, setPost] = useState<any>(false);

  interface IForgotPassword {
    username: string;
  }

  interface IForgotPasswordResponse {
    status: boolean;
    message: string;
    access_token: string;
    data: any;
  }

  const handleSubmit = (values: IForgotPassword) => {
    if (setUser) setUser(values.username);
    axios
      .post<IForgotPasswordResponse>(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, {
        email: values.username,
      })
      .then((response) => {
        if (response.status) {
          setPost(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setNoUser(true);
        setErrors(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { username: '' },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <PageWrapper isProtected={false} title={'Change Password'} className={classNames('bg-light')}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-6 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center mt-5'>
                  <Link
                    to='/'
                    className={classNames('text-decoration-none  fw-bold display-2', {
                      'text-dark': !darkModeStatus,
                      'text-light': darkModeStatus,
                    })}>
                    <img src={Logo1} alt='logo' height={120} />
                  </Link>
                </div>
                <ForgotPasswordHeader />
                <form className='row g-4 align-items-center justify-content-center mb-4'>
                  <div className='col-lg-9'>
                    <FormGroup id='username' isFloating label='Email'>
                      <Input
                        autoComplete='username'
                        name='username'
                        value={formik.values.username}
                        isTouched={formik.touched.username}
                        invalidFeedback={formik.errors.username}
                        isValid={formik.isValid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => {
                          formik.setErrors({});
                        }}
                        disabled={post}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-lg-9 my-5'>
                    <div>
                      {hasErrors ? (
                        <div>
                          <div
                            className='invalid-feedback'
                            style={{
                              display: 'block',
                              width: '100%',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}>
                            Incorrect Role.
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {noUser ? (
                        <div>
                          <div
                            className='invalid-feedback'
                            style={{
                              display: 'block',
                              width: '100%',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}>
                            Incorrect user.
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {post ? (
                        <div>
                          <div
                            className='valid-feedback'
                            style={{
                              display: 'block',
                              width: '100%',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}>
                            Request success, <br />
                            check email for reset link
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {!post ? (
                        <Button
                          color='dark'
                          className='w-100 py-3 fw-bold'
                          onClick={formik.handleSubmit}>
                          Request password reset
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
ForgotPassword.propTypes = {
  isSignUp: PropTypes.bool,
};
ForgotPassword.defaultProps = {
  isSignUp: false,
};

export default ForgotPassword;
