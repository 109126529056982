import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
  dashboardMenu,
  demoPages,
  layoutMenu,
  userMenu,
  cmsMenu,
  organizationMenu,
  catalogMenu,
  skills,
  orders,
  categories,
  applications,
} from '../../menu';
import ThemeContext from '../../contexts/themeContext';

import Icon from '../../components/icon/Icon';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import Popovers from '../../components/bootstrap/Popovers';

const Aside = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);

  const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

  const isModernDesign = process.env.REACT_APP_MODERN_DESIGN === 'true';

  const constraintsRef = useRef(null);

  const [doc, setDoc] = useState(true);

  const { t } = useTranslation(['translation', 'menu']);

  return (
    <>
      <motion.aside
        style={asideStyle as MotionStyle}
        className={classNames(
          'aside',
          { open: asideStatus },
          {
            'aside-touch-bar': hasTouchButton && isModernDesign,
            'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
            'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
          },
        )}>
        <div className='aside-head'>
          <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
        </div>
        <div className='aside-body'>
          <Navigation menu={dashboardMenu} id='aside-dashboard' />
          <NavigationLine />
          <Navigation menu={organizationMenu} id='aside-organization' />
          <NavigationLine />
          <Navigation menu={userMenu} id='aside-menu' />
          <NavigationLine />
          <Navigation menu={catalogMenu} id='aside-catalog' />
          <NavigationLine />
          <Navigation menu={skills} id='aside-skills' />
          <NavigationLine />
          <Navigation menu={orders} id='aside-orders' />
          <NavigationLine />
          <Navigation menu={categories} id='aside-categories' />
          <NavigationLine />
          <Navigation menu={applications} id='aside-applications' />
          <NavigationLine />
          <nav aria-label="aside-cms">
            <ul id="aside-cms" className="navigation">
              <li className="navigation-item">
                <a className="navigation-link navigation-link-pill" href={`${process.env.REACT_APP_CMS_URL}`}>
                  <span className="navigation-link-info">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon--material svg-icon navigation-icon" data-name="Material--ControlCamera"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M7.3 13.77L5.54 12l1.76-1.77-1.76-1.77L2 12l3.54 3.54zm8.24 4.69l-1.77-1.76L12 18.46l-1.77-1.76-1.77 1.76L12 22zm2.92-2.92L22 12l-3.54-3.54-1.76 1.77L18.46 12l-1.76 1.77zM12 5.54l1.77 1.76 1.77-1.76L12 2 8.46 5.54l1.77 1.76z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                    <span className="navigation-text">CMS</span>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <NavigationLine />

          {!doc && (
            <>
              <Navigation menu={demoPages} id='aside-demo-pages' />
              <NavigationLine />
              <Navigation menu={layoutMenu} id='aside-menu' />
              <NavigationLine />
              <nav>
                <div className='navigation'>
                  <div className='navigation-item'>
                    <span className='navigation-link navigation-link-pill'>
                      <span className='navigation-link-info'>
                        <span className='navigation-text'>
                          <Popovers
                            title='Aside.tsx'
                            desc={<code>src/layout/Aside/Aside.tsx</code>}>
                            Aside
                          </Popovers>
                          <code className='ps-3'>Aside.tsx</code>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </nav>
            </>
          )}
        </div>
        {/* 
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span>
							</div>
						</div>
					</nav>
					<User />
				</div> 
				*/}
      </motion.aside>
      {asideStatus && hasTouchButton && isModernDesign && (
        <>
          <motion.div className='aside-drag-area' ref={constraintsRef} />
          <Tooltips title='Toggle Aside' flip={['top', 'right']}>
            <motion.div
              className='aside-touch'
              drag='x'
              whileDrag={{ scale: 1.2 }}
              whileHover={{ scale: 1.1 }}
              dragConstraints={constraintsRef}
              // onDrag={(event, info) => console.log(info.point.x, info.point.y)}
              dragElastic={0.1}
              style={{ x, zIndex: 1039 }}
              onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
            />
          </Tooltips>
        </>
      )}
    </>
  );
};

export default Aside;
