import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { SignIn } from '@clerk/clerk-react';

interface ILoginProps {
  isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
  return (
    <PageWrapper isProtected={false} title='Login' className={classNames('bg-light')}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-6 col-lg-6 col-md-8 shadow-3d-container'>
            <div className='flex'>
              <div className='mx-auto'>
                <SignIn routing={'virtual'} path={'/sign-in'} signUpUrl='/sign-up' />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

Login.propTypes = {
  isSignUp: PropTypes.bool,
};

Login.defaultProps = {
  isSignUp: false,
};

export default Login;
